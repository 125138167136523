import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import { validateFunc } from '../../constraints/constraints'
import { updateOrderStatus, getConfiguration } from '../../apollo'
import Loader from 'react-loader-spinner'
import {
  Box,
  Divider,
  Grid,
  Typography,
  Alert,
  Input,
  Button,
  useTheme
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'
import DialogSetTimePrepar from './DialogSetTimePrepar'
import moment from 'moment'

// constants
const UPDATE_STATUS = gql`
  ${updateOrderStatus}
`

const GET_CONFIGURATION = gql`
  ${getConfiguration}
`

function Order(props) {
  const theme = useTheme()
  const { order, t } = props
  const [reason, reasonSetter] = useState('')
  const [reasonError, reasonErrorSetter] = useState(null)
  const [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')
  const [isOpenDialogSetTime, setIsOpenDialogSetTime] = useState(false)

  const closeDialogSeTime = useCallback(() => {
    setIsOpenDialogSetTime(false)
  }, [])

  const onCompleted = ({ updateOrderStatus, acceptOrder }) => {
    if (updateOrderStatus) {
      successSetter(t('OrderStatusUpdated'))
      closeDialogSeTime()
    }
    setTimeout(onDismiss, 5000)
  }
  const onError = error => {
    errorSetter(error.message)
    setTimeout(onDismiss, 5000)
  }
  const { data } = useQuery(GET_CONFIGURATION)
  const [mutate, { loading }] = useMutation(UPDATE_STATUS, {
    onError,
    onCompleted
  })

  const approveTimePrepare = useCallback(time => {
    mutate({
      variables: {
        id: order._id,
        time: String(time),
        status: 'ACCEPTED'
      }
    })
  }, [])

  const validateReason = () => {
    const reasonError = !validateFunc({ reason }, 'reason')
    reasonErrorSetter(reasonError)
    return reasonError
  }

  const onDismiss = () => {
    errorSetter('')
    successSetter('')
  }

  function calculatePrice(food) {
    var foodPrice = food?.variation?.price || 0
    if (food?.addons) {
      food.addons.forEach(addons => {
        if (addons?.options) {
          addons.options.forEach(addon => {
            foodPrice += addon?.price || 0
          })
        }
      })
    }
    return foodPrice
  }

  const diffCreateVSOrderDate = useMemo(() => {
   return moment(order && order.orderDate).diff(order && order.createdAt, 'seconds') > 10
  }, [order])

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  if (!props.order) return null
  return (
    <>
      <Box className={[classes.container, classes.pb]}>
        <Box className={classes.flexRow}>
          <Box item className={classes.heading}>
            <Typography variant="h6" className={classes.text}>
              {t('Order')} # {order.orderId}
            </Typography>
          </Box>
        </Box>
        <Box className={[classes.container, classes.bgPrimary]}>
          <Typography className={classes.itemHeader} variant="h6">
            {t('Items')}
          </Typography>
          <Box container className={classes.innerContainer}>
            {order &&
              order.items.map(item => (
                <>
                  <Grid container mb={1} mt={1}>
                    <Grid item lg={1}>
                      <Typography
                        className={[classes.quantity, classes.textBlack,classes.mediumBoldText]}
                        variant="p">
                        {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid container lg={11} sx={{ gap: '5px' }}>
                      <Grid container lg={12}>
                        <Grid className={[classes.textBlack,classes.mediumBoldText]} item lg={10}>
                          {`${item.title}`}
                        </Grid>
                        <Grid
                          className={[classes.price, classes.textPrimary,classes.mediumBoldText]}
                          item
                          lg={2}>
                          {data && data.configuration.currencySymbol}{' '}
                          {parseFloat(calculatePrice(item)).toFixed(2)}
                        </Grid>
                      </Grid>
                      {/* Variation */}
                      <Grid container lg={12}>
                        <Grid className={[classes.textBlack]} item lg={10}>
                          <Typography
                            variant="caption"
                            className={[classes.mediumBoldTextDefautColor]}>
                            {`# ${t('Variation')}: ${
                              item.variation && item.variation.title
                            }`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}>
                          <Typography
                            variant="caption"
                            className={[classes.mediumBoldTextDefautColor]}>
                            {data && data.configuration.currencySymbol}{' '}
                            {parseFloat(
                              item.variation && item.variation.price
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* Addon */}
                      {item.addons &&
                        item.addons.map((addon) => {
                          return (
                            addon.options &&
                            addon.options.map(option => (
                              <Grid container key={option._id} lg={12}>
                                <Grid
                                  className={classes.textBlack}
                                  item
                                  lg={10}>
                                  <Typography
                                    variant="caption"
                                    className={[classes.mediumText]}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{`${option.title} - ${addon.title}`}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={2}>
                                  <Typography
                                    variant="caption"
                                    className={[classes.mediumText]}>
                                    {data && data.configuration.currencySymbol}{' '}
                                    {parseFloat(option.price).toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))
                          )
                        })}
                    </Grid>
                  </Grid>
                  {item.specialInstructions.length > 0 && (
                    <Typography variant="text" className={classes.textBlack}>
                      {t('SpecialInstructions')}
                    </Typography>
                  )}
                  <Divider />
                </>
              ))}
          </Box>
        </Box>
        <Box mt={3} className={[classes.container, classes.bgPrimary]}>
          <Typography className={classes.itemHeader} variant="h6">
            {t('Charges')}
          </Typography>
          <Box container className={classes.innerContainer}>
            {order.coupon?.discount && (
              <>
                <Grid container mb={1} mt={1}>
                  <Grid className={classes.textBlack} item lg={10}>
                    {t('DiscountCoupon')}
                  </Grid>
                  <Grid className={[classes.textBlack]} item lg={2}>
                    {order.coupon.title}
                  </Grid>
                </Grid>
                <Grid container mb={1} mt={1}>
                  <Grid className={classes.textBlack} item lg={10}>
                    {t('DiscountAmount')}
                  </Grid>
                  <Grid
                    className={[classes.textBlack, classes.discountAmount]}
                    item
                    lg={2}>
                    <span className={[classes.subtract]}>-</span>
                    {data && data.configuration.currencySymbol}{' '}
                    {(
                      ((order.orderAmount -
                        order.deliveryCharges -
                        order.tipping -
                        order.taxationAmount) /
                        (100 - order.coupon.discount)) *
                      order.coupon.discount
                    ).toFixed(2)}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={10}>
                {t('Subtotal')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {(
                  order.orderAmount -
                  order.deliveryCharges -
                  order.tipping -
                  order.taxationAmount
                ).toFixed(2)}
              </Grid>
            </Grid>
            <Divider />
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={10}>
                {t('DeliveryFee')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {order && order.deliveryCharges.toFixed(2)}
              </Grid>
            </Grid>
            <Divider />
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={10}>
                {t('TaxCharges')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {order && order.taxationAmount.toFixed(2)}
              </Grid>
            </Grid>
            <Divider />
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={10}>
                {t('Tip')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {order && order.tipping.toFixed(2)}
              </Grid>
            </Grid>
            <Divider />
            <Grid container mb={1} mt={5}>
              <Grid className={classes.textBlack} item lg={10}>
                {t('Total')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {order && order.orderAmount.toFixed(2)}
              </Grid>
            </Grid>
            <Divider />
          </Box>
        </Box>
        <Box mb={3} className={[classes.container, classes.bgPrimary]}>
          <Typography className={classes.itemHeader} variant="h6">
            {t('PaymentMethod')}
          </Typography>
          <Box container className={classes.innerContainer}>
            <Grid container mb={1} mt={1}>
              <Grid item lg={3} />
              <Grid
                className={[classes.price, classes.textPrimary, classes.pd]}
                item
                lg={6}>
                {order.paymentMethod}
              </Grid>
              <Grid item lg={3} />
            </Grid>
            <Divider />
            <Grid container mb={1} mt={2}>
              <Grid item lg={10}>
                <Typography className={[classes.textBlack]} variant="p">
                  {t('PaidAmount')}
                </Typography>
              </Grid>
              <Grid
                className={[classes.price, classes.textPrimary]}
                item
                lg={2}>
                {data && data.configuration.currencySymbol}{' '}
                {order && order.paidAmount ? order.paidAmount.toFixed(2) : 0}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* DeliveryInfo */}
        <Box mt={3} className={[classes.container, classes.bgPrimary]}>
          <Typography className={classes.itemHeader} variant="h6">
          {t('DeliveryInfo')}
          </Typography>
          <Box container className={classes.innerContainer}>
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={10}>
              {t('DeliveryType')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={2}>
                {order.isPickedUp ? t('Pickup'): t('Delivery')}
              </Grid>
            </Grid>
            <Divider />
            <Grid container mb={1} mt={1}>
              <Grid className={classes.textBlack} item lg={8}>
              {order.isPickedUp ? t('PickupTime'): t('DeliveryTime')}
              </Grid>
              <Grid className={[classes.textBlack]} item lg={4}>
              {diffCreateVSOrderDate && order.orderDate && `${moment(order.orderDate).format("DD-MM-YYYY")} | ${moment(order.orderDate).format("LT")}`}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {order.orderStatus !== 'CANCELLED' &&
          order.orderStatus !== 'DELIVERED' && (
            <>
              {loading && (
                <Loader
                  className="text-center"
                  type="TailSpin"
                  color={theme.palette.error.lightest}
                  height={40}
                  width={40}
                  visible={loading}
                />
              )}
              <Box className={classes.btnBox}>
                <Button
                  className={globalClasses.button}
                  disabled={
                    order.orderStatus !== 'CANCELLED' &&
                    order.orderStatus !== 'PENDING'
                  }
                  onClick={() => {
                    setIsOpenDialogSetTime(true)
                  }}>
                  {order && order.status === true ? t('Accepted') : t('Accept')}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  className={globalClasses.button}
                  disabled={order.orderStatus === 'CANCELLED'}
                  onClick={() => {
                    if (validateReason()) {
                      mutate({
                        variables: {
                          id: order._id,
                          status: 'CANCELLED',
                          reason: order.reason
                        }
                      })
                    }
                  }}>
                  {order.status === false ? t('Cancelled') : t('Cancel')}
                </Button>
                <Input
                  name="reason"
                  id="input-reason"
                  placeholder={t('PHReasonIfRejected')}
                  type="text"
                  disableUnderline
                  value={(order && order.reason) || reason}
                  onChange={event => {
                    reasonSetter(event.target.value)
                  }}
                  className={[globalClasses.input, classes.inputLength]}
                />
              </Box>
              {reasonError ? null : null}
            </>
          )}
        <Box mt={2}>
          {success && (
            <Alert
              className={globalClasses.alertSuccess}
              variant="filled"
              severity="success">
              {success}
            </Alert>
          )}
          {error && (
            <Alert
              className={globalClasses.alertError}
              variant="filled"
              severity="error">
              {error}
            </Alert>
          )}
        </Box>
      </Box>
      <DialogSetTimePrepar
        isOpen={isOpenDialogSetTime}
        close={closeDialogSeTime}
        approve={approveTimePrepare}
      />
    </>
  )
}
export default withTranslation()(Order)
