import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import React, { memo, useState } from 'react'
import useGlobalStyles from '../../utils/globalStyles'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
function DialogSetTimePrepar(props) {
  const { isOpen, close, approve } = props
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { t } = useTranslation()
  const TIMES = [10, 20, 30, 40, 50, 60, 70, 80, 90]
  const [timeSelect, setTimeSelect] = useState(10)

  return (
    <Dialog
      onClose={() => close()}
      open={isOpen}
      scroll="body"
      PaperProps={{
        style: {
          borderRadius: 30,
          overflowY: 'visible',
          padding: 20
        }
      }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FormControl>
        <Typography className={classes.titleDialogSetTime} variant="h6">{t('setTimeForPreparation')} </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={timeSelect}
            name="radio-buttons-group"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 2,
              color: 'black'
            }}>
            {TIMES.map(value => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio onChange={() => setTimeSelect(value)} />}
                label={`${value} ${t('mins')}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          className={globalClasses.button}
          onClick={() => {
            approve(timeSelect)
          }}>
          {t('Accept')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default memo(DialogSetTimePrepar)
