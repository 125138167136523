/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import ShopTypeComponent from '../components/ShopType/ShopType'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import { getShopTypes, deleteShopType } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import SearchBar from '../components/TableHeader/SearchBar'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Typography,
  ListItemIcon,
  Grid
} from '@mui/material'
import { customStyles } from '../utils/tableCustomStyles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import ConfigurableValues from '../config/constants'
import IMAGES from '../utils/image'

const GET_SHOPTYPE = gql`
  ${getShopTypes}
`
const DELETE_SHOPTYPE = gql`
  ${deleteShopType}
`
const ShopType = props => {
  const { t } = props
  const { PAID_VERSION } = ConfigurableValues()
  const [editModal, setEditModal] = useState(false)
  const [shopType, setShopType] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const onChangeSearch = e => setSearchQuery(e.target.value)

  const toggleModal = value => {
    setEditModal(!editModal)
    setShopType(value)
  }
  const closeEditModal = () => {
    setEditModal(false)
  }

  const [mutate, { loading }] = useMutation(DELETE_SHOPTYPE, {
    update(cache, { data: { deleteShopType } }) {
      if (!deleteShopType._id) return
      cache.evict({ id: `ShopType:${deleteShopType._id}`})
    }
  })

  const { data, error: errorQuery, loading: loadingQuery, refetch } = useQuery(
    GET_SHOPTYPE
  )
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }
  const columns = [
    {
      name: t('Image'),
      cell: row => (
        <>
          <img
            className="img-responsive"
            style={{ width: 30, height: 30, borderRadius: 15, objectFit: "cover" }}
            src={
              row.image ||
              IMAGES.IMAGE_DEFAUT_SHOPTYPE
            }
            alt=''
          />
        </>
      )
    },
    {
      name: t('Name'),
      sortable: true,
      selector: 'name'
    },
    {
      name: t('Description'),
      sortable: true,
      selector: 'description'
    },
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ]
  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()

                  if (PAID_VERSION) toggleModal(row)
                  else {
                    setIsOpen(true)
                    setTimeout(() => {
                      setIsOpen(false)
                    }, 5000)
                  }
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.preventDefault()

                  if (PAID_VERSION)
                    mutate({
                      variables: { id: row._id, }
                    })
                  else {
                    setIsOpen(true)
                    setTimeout(() => {
                      setIsOpen(false)
                    }, 5000)
                  }
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  const filtered = useMemo(() => {
    const regex =
      searchQuery.length > 2 ? new RegExp(searchQuery.toLowerCase(), 'g') : null
    const result =
      (searchQuery.length < 3 && data?.shopTypes) ||
      (data?.shopTypes &&
        data?.shopTypes.filter(shopType => {
          return shopType.name.toLowerCase().search(regex) > -1
        })) ||
      []
    return result
  }, [searchQuery,data])

  const globalClasses = useGlobalStyles()
  return (
    <>
      <Header />
      {isOpen && (
        <Alert message={t('AvailableAfterPurchasing')} severity="warning" />
      )}
      {/* Page content */}
      <Container className={globalClasses.flex} fluid>
        <Grid container mb={3}>
          <Grid item xs={12} md={7}>
            <ShopTypeComponent />
          </Grid>
        </Grid>
        {errorQuery ? <span>{`Error! ${errorQuery.message}`}</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <SearchBar
                value={searchQuery}
                onChange={onChangeSearch}
                onClick={() => refetch()}
              />
            }
            title={<TableHeader title={t('ShopTypes')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loading}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            defaultSortField="title"
            customStyles={customStyles}
            selectableRows
            paginationIconLastPage=""
            paginationIconFirstPage=""
          />
        )}

        <Modal
          open={editModal}
          onClose={() => {
            toggleModal(null)
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <ShopTypeComponent shopType={shopType} onClose={closeEditModal} />
        </Modal>
      </Container>
    </>
  )
}
export default withTranslation()(ShopType)
