import React, { useEffect, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'

import { Box, Typography, Input, Button, Alert, Grid } from '@mui/material'

import { editShopType, createShopType, getShopTypes } from '../../apollo'
import useGlobalStyles from '../../utils/globalStyles'
import useStyles from '../styles'
import useLocalStyles from './styles'
import IMAGES from '../../utils/image'
import ConfigurableValues from '../../config/constants'

const CREATE_SHOPTYPE = gql`
  ${createShopType}
`
const EDIT_SHOPTYPE = gql`
  ${editShopType}
`
const GET_SHOP_TYPE = gql`
  ${getShopTypes}
`

function ShopType(props) {
  const localClasses = useLocalStyles()
  const { CLOUDINARY_UPLOAD_URL, CLOUDINARY_FOOD } = ConfigurableValues()
  const { t } = props
  const arrayFieldConfig = [
    {
      field: 'name',
      isRequired: true,
      placeholder: t('PHShopTypeName'),
      title: t('Name')
    },
    {
      field: 'description',
      isRequired: true,
      placeholder: t('Description'),
      title: t('Description')
    }
  ]
  const mutation = props.shopType ? EDIT_SHOPTYPE : CREATE_SHOPTYPE
  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')
  const [isSave, setIsSave] = useState(false)
  const [shopType, setShopType] = useState(props.shopType || {})
  const [imageShopType,setImageShopType] = useState('')
  const [errorForm, setErrorForm] = useState({})
  const imageToBase64 = imgUrl => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      if (fileReader.result) {
        setImageShopType(fileReader.result)
      }
    }
    fileReader.readAsDataURL(imgUrl)
  }
  
  const filterImage = event => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image =>
      String(image.name)
        .toLocaleLowerCase()
        .match(/\.(jpg|jpeg|png|gif)$/)
    )
    return images.length ? images[0] : undefined
  }
  const handleFileSelect = event => {
    
    let result
    result = filterImage(event)
    if (result) imageToBase64(result)
  }
  const uploadImageToCloudinary = async uploadType => {
    if (!uploadType) return

    const apiUrl = CLOUDINARY_UPLOAD_URL
    const data = new FormData()
    data.append('file', uploadType)
    data.append('upload_preset', CLOUDINARY_FOOD)
    try {
      const result = await fetch(apiUrl, {
        body: data,
        method: 'POST'
      })
      const imageData = await result.json()
      if(mutation !== EDIT_SHOPTYPE){
        setImageShopType('')
      }
      return imageData.secure_url
    } catch (e) {
      console.log(e)
    }
  }
  const onCompleted = data => {
    const message = props.shopType
      ? t('ShopTypeUpdateSuccessfully')
      : t('ShopTypeAddedSuccessfully')
    successSetter(message)
    mainErrorSetter('')
    if (mutation === CREATE_SHOPTYPE) setShopType({})
    setTimeout(hideAlert, 3000)
  }
  const onError = error => {
    const message = `${t('ActionFailedTryAgain')} ${error}`
    successSetter('')
    mainErrorSetter(message)
    setTimeout(hideAlert, 3000)
  }
  const [mutate, { loading }] = useMutation(mutation, {
    onError,
    onCompleted,
    update(cache, { data: { createShopType } }) {
      if (props.shopType) return
      const { shopTypes } = cache.readQuery({ query: GET_SHOP_TYPE })
      cache.writeQuery({
        query: GET_SHOP_TYPE,
        data: { shopTypes: [...shopTypes, createShopType] }
      })
    }
  })
  const hideAlert = () => {
    mainErrorSetter('')
    successSetter('')
  }
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const validate = () => {
    let error = {}
    arrayFieldConfig.forEach(fieldItem => {
      if (fieldItem.isRequired) {
        error[fieldItem.field] = !(
          shopType[fieldItem.field] && shopType[fieldItem.field].trim()
        )
          ? `${fieldItem.field} is required`
          : undefined
      }
    })
    setErrorForm(error)
    return !Object.keys(error).some(k => error[k])
  }

  return (
    <Box container className={classes.container}>
      <Box className={classes.flexRow}>
        <Box
          item
          className={props.shopType ? classes.headingBlack : classes.heading2}>
          <Typography variant="h6" className={classes.textWhite}>
            {props.shopType ? t('EditShopType') : t('AddShopType')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.form}>
        <form>
          {arrayFieldConfig.map(item => (
            <Box key={item.field}>
              <Typography className={classes.labelText}>
                {item.title}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                name={item.field}
                placeholder={item.placeholder}
                type="text"
                value={shopType[item.field] || ''}
                onChange={e => {
                  setShopType({ ...shopType, [item.field]: e.target.value })
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  errorForm[item.field] && globalClasses.inputError
                ]}
              />
            </Box>
          ))}
          <Box
            mt={3}
            style={{ alignItems: 'center' }}
            className={globalClasses.flex}>
            <img
              className={localClasses.image}
              alt="..."
              src={imageShopType || shopType.image || IMAGES.IMAGE_DEFAUT_SHOPTYPE}
            />
            <label htmlFor={shopType._id || 'file-upload'} className={localClasses.fileUpload}>
              {t('UploadAnImage')}
            </label>
            <input
              className={localClasses.file}
              id={shopType._id || 'file-upload'}
              type="file"
              accept="image/*"
              onChange={event => {
                handleFileSelect(event)
              }}
            />
          </Box>
          <Box>
            <Button
              className={globalClasses.button}
              disabled={isSave}
              onClick={async e => {
                e.preventDefault()
                setIsSave(true)
                if (!isSave && validate()) {
                  try {
                    const imgUpload = await uploadImageToCloudinary(imageShopType)
                    await mutate({
                      variables: {
                        shopTypeInput: {
                          ...shopType,
                          image: imageShopType && imgUpload || shopType.image,
                          __typename: undefined
                        }
                      }
                    })
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsSave(false)
                  }
                }
              }}>
              {t('Save')}
            </Button>
          </Box>
          <Box mt={2}>
            {success && (
              <Alert
                className={globalClasses.alertSuccess}
                variant="filled"
                severity="success">
                {success}
              </Alert>
            )}
            {mainError && (
              <Alert
                className={globalClasses.alertError}
                variant="filled"
                severity="error">
                {mainError}
              </Alert>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default withTranslation()(ShopType)
