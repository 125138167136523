import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  image: {
    width: 120,
    height: 120,
    borderRadius: 30,
    objectFit: "cover",
  },
  file: {
    display: 'none'
  },
  fileUpload: {
    marginTop: 10,
    backgroundColor: theme.palette.primary.main2,
    display: 'inlineBlock',
    padding: '3px 6px',
    cursor: 'pointer',
    color: theme.palette.common.white,
    borderRadius: 10,
    fontSize: 12
  }
}))

export default useStyles
